<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.14066 12.3999C7.14066 13.3201 6.39522 14.0661 5.47566 14.0661C4.55611 14.0661 3.81066 13.3201 3.81066 12.3999M7.14066 12.3999C7.14066 11.4798 6.39522 10.7338 5.47566 10.7338C4.55611 10.7338 3.81066 11.4798 3.81066 12.3999M7.14066 12.3999H8.86791M3.81066 12.3999C2.38734 12.3999 1.875 11.8205 1.875 10.5546M12.1979 12.3999C12.1979 13.3201 11.4525 14.0661 10.5329 14.0661C9.61336 14.0661 8.86791 13.3201 8.86791 12.3999M12.1979 12.3999C12.1979 11.4798 11.4525 10.7338 10.5329 10.7338C9.61336 10.7338 8.86791 11.4798 8.86791 12.3999M12.1979 12.3999C13.3884 12.3999 14.125 11.9647 14.125 10.9231V7.63826M1.875 4.91414H6.11849M1.875 7.34987H4.35696M1.875 1.93382C1.875 1.93382 7.8407 1.93382 8.86791 1.93382C9.89512 1.93382 9.89512 2.96171 9.89512 2.96171V4.04893M14.125 7.63826H9.89512V4.04893M14.125 7.63826C14.125 7.63826 14.125 7.84657 14.125 6.46852C14.125 5.09048 12.8279 4.04893 11.707 4.04893C10.5861 4.04893 9.89512 4.04893 9.89512 4.04893"
      stroke="currentColor"
      stroke-width="1.4"
      stroke-linecap="round"
    />
  </svg>
</template>
